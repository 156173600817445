const frontOneHour = (fmt, isToday) => {
  let currentTime;
  if (isToday) {
    currentTime = new Date(new Date().getTime());
  } else {
    currentTime = new Date(new Date().getTime() - 144 * 60 * 60 * 1000);
  }
  let o = {
    'M+': currentTime.getMonth() + 1, // 月份
    'd+': currentTime.getDate(), // 日
    'h+': currentTime.getHours(), // 小时
    'm+': currentTime.getMinutes(), // 分
    's+': currentTime.getSeconds(), // 秒
    'q+': Math.floor((currentTime.getMonth() + 3) / 3), // 季度
    S: currentTime.getMilliseconds() // 毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, `${currentTime.getFullYear()}`.substr(4 - RegExp.$1.length));
  }
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length));
    }
  }
  return fmt;
};
export { frontOneHour };
