<template>
  <span class="time-slelector">
    <el-date-picker
      v-model="vModel[0]"
      :type="type"
      :picker-options="pickerBeginDateBefore"
      :style="styleCss"
      :value-format="valueFormat"
      :format="format"
      :placeholder="startPlaceholder"
      :clearable="clearable"
      :disabled="disabled"
      :editable="editable"
    ></el-date-picker
    >{{ rangeSeparator
    }}<el-date-picker
      v-model="vModel[1]"
      :type="type"
      :picker-options="pickerBeginDateAfter"
      :style="styleCss"
      :value-format="valueFormat"
      :format="format"
      :placeholder="endPlaceholder"
      :clearable="clearable"
      :editable="editable"
    ></el-date-picker>
  </span>
</template>

<script>
/**
 * 日期范围选择器组件
 * 接收参数
 * @vModel                   类型Array  length=0:开始时间  length=1:结束时间
 * @disabled                 是否禁用
 * @startPlaceholder         开始时间提示
 * @endPlaceholder           接收时间提示
 * @rangeSeparator           中间提示
 * @clearable                是否展示清空按钮 默认 false
 * @editable                 文本是否可输入   默认 false
 * @type                     选择类型。date:天  week:周  month:月
 * @valueFormat              返回数据格式
 * @styleCss                 样式控制Style
 * @format                   显示再输入框的格式
 * 可监听事件
 * 无
 */
export default {
  props: {
    vModel: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    todayNow: {
      type: Boolean,
      default: true
    },
    startPlaceholder: {
      type: String,
      default: ''
    },
    endPlaceholder: {
      type: String,
      default: ''
    },
    rangeSeparator: {
      type: String,
      default: ''
    },
    clearable: {
      type: Boolean,
      default: true
    },
    editable: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'date'
    },
    valueFormat: {
      type: String,
      default: 'yyyy-MM-dd'
    },
    styleCss: {
      type: String,
      default: 'width:232px;'
    },
    format: {
      type: String,
      default: 'yyyy-MM-dd'
    }
  },
  data() {
    return {
      pickerBeginDateBefore: {
        disabledDate: time => {
          if (this.vModel[1]) {
            return time.getTime() > Date.now() || time.getTime() > new Date(this.vModel[1]).getTime();
          } else {
            // 开始时间是否可选择当天
            if (this.todayNow) {
              return time.getTime() > Date.now();
            } else {
              return time.getTime() > Date.now() - 3600 * 1000 * 24;
            }
          }
        }
      },
      pickerBeginDateAfter: {
        disabledDate: time => {
          if (this.vModel[0]) {
            if (this.todayNow) {
              return time.getTime() > Date.now() || time.getTime() < new Date(this.vModel[0]).getTime();
            } else {
              return time.getTime() > Date.now() - 3600 * 1000 * 24 || time.getTime() < new Date(this.vModel[0]).getTime() - 3600 * 1000 * 24;
            }
          } else {
            // 结束是否可选择当天
            if (this.todayNow) {
              return time.getTime() > Date.now();
            } else {
              return time.getTime() > Date.now() - 3600 * 1000 * 24;
            }
          }
        }
      }
    };
  }
};
</script>

<style scoped>
.time-slelector {
  color: #000;
}
</style>
