<template>
  <div class="lineCharts"></div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  data() {
    return {
      itemData: {}
    };
  },
  props: {
    ChartsData: {
      type: Object,
      require: true,
      default: null
    }
  },
  mounted() {
    if (this.ChartsData !== null) {
      this.itemData = JSON.parse(JSON.stringify(this.ChartsData));
      for (let i = 0; i < this.itemData.running.length; i++) {
        this.itemData.running[i] = (this.itemData.running[i] / 1000 / 60).toFixed(2);
      }
      for (let i = 0; i < this.itemData.free.length; i++) {
        this.itemData.free[i] = (this.itemData.free[i] / 1000 / 60).toFixed(2);
      }
      for (let i = 0; i < this.itemData.alarm.length; i++) {
        this.itemData.alarm[i] = (this.itemData.alarm[i] / 1000 / 60).toFixed(2);
      }
      for (let i = 0; i < this.itemData.offline.length; i++) {
        this.itemData.offline[i] = (this.itemData.offline[i] / 1000 / 60).toFixed(2);
      }
      this.init();
    }
  },
  watch: {
    ChartsData() {
      this.itemData = JSON.parse(JSON.stringify(this.ChartsData));
      for (let i = 0; i < this.itemData.running.length; i++) {
        this.itemData.running[i] = (this.itemData.running[i] / 1000 / 60).toFixed(2);
      }
      for (let i = 0; i < this.itemData.free.length; i++) {
        this.itemData.free[i] = (this.itemData.free[i] / 1000 / 60).toFixed(2);
      }
      for (let i = 0; i < this.itemData.alarm.length; i++) {
        this.itemData.alarm[i] = (this.itemData.alarm[i] / 1000 / 60).toFixed(2);
      }
      for (let i = 0; i < this.itemData.offline.length; i++) {
        this.itemData.offline[i] = (this.itemData.offline[i] / 1000 / 60).toFixed(2);
      }
      this.init();
    },
    deep: true
  },
  methods: {
    init() {
      let chartDom = document.querySelector('.lineCharts');
      let myChart = echarts.init(chartDom);
      let option;

      option = {
        tooltip: {
          trigger: 'axis',
          formatter(params) {
            let str = `${params[0].name}<br/>`;
            params.forEach(item => {
              let min = `${(item.value % 60).toFixed(0)}`;
              let h = `${Math.floor(item.value / 60)}`;
              str = str.concat(`${item.marker + item.seriesName}: ${h}小时${min}分钟<br/>`);
            });
            return str;
          }
        },
        grid: {
          top: '6%',
          right: '3%',
          left: '5%',
          bottom: '20%'
        },
        legend: {
          data: ['运行时长', '待机时长', '故障时长', '离线时长'],
          bottom: '1%',
          left: '5%',
          itemWidth: 13,
          itemHeight: 13,
          itemGap: 30
        },
        dataZoom: [
          {
            type: 'inside'
          }
        ],
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.itemData.xAxisValues
        },
        yAxis: {
          type: 'value',
          name: '时长(min)'
        },
        series: [
          {
            name: '运行时长',
            type: 'line',
            data: this.itemData.running,
            itemStyle: {
              color: '#19a572',
              borderColor: '#fff'
            }
          },
          {
            name: '待机时长',
            type: 'line',
            data: this.itemData.free,
            itemStyle: {
              color: '#f39800',
              borderColor: '#fff'
            }
          },
          {
            name: '故障时长',
            type: 'line',
            data: this.itemData.alarm,
            itemStyle: {
              color: '#EA3837',
              borderColor: '#fff'
            }
          },
          {
            name: '离线时长',
            type: 'line',
            data: this.itemData.offline,
            itemStyle: {
              color: '#DCDCDC',
              borderColor: '#fff'
            }
          }
        ]
      };

      option && myChart.setOption(option);
    }
  }
};
</script>

<style scoped>
.lineCharts {
  height: 600px;
}
</style>
